<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-auto" />
        </colgroup>
      </template>
      <template v-slot:item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template v-slot:item-Wagen="{ item }">
        <span v-if="!item.nummerplaat" class="text-red-500">Geen enkele wagen ingegeven</span>
        <span v-else>{{ item.nummerplaat }} ({{ item.VIN }})</span>
      </template>
      <template v-slot:item-Google_afstand="{ item }">
        <i class="fas fa-road text-gray-400"></i>
        {{ formatDecimals(item.kilometers) }} km
      </template>
      <template v-slot:item-Google_tijd="{ item }">
        <i class="fas fa-clock text-gray-400"></i>
        {{ millisecondsToHMS(item.seconden * 1000) }}
      </template>
      <template v-slot:item-Enkele_ritten="{ item }">
        <i class="fas fa-car text-gray-400"></i>
        {{ item.aantal_enkele_ritten }}
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import useGetApi from '@/hooks/useGetApi'
import { monthToString } from '@/functions/formatDate'
import { millisecondsToHMS } from '@/functions/formatDate'
import { formatDecimals } from '@/functions/formatNumber'

import UITableResponsive from '@/components/UI/Table/Responsive'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const headers = ['Datum', 'Wagen', 'Google_afstand', 'Google_tijd', 'Enkele_ritten']

const { data } = useGetApi('/api/dashboard/stats/wagens/gereden', props.filters, { watch: true })
</script>
